import styles from "./Main.module.css";
import Header from './components/Header'
import DescriptionMain from './components/DescriptionMain'
import Trainings from './components/Trainings'
import Prices from './components/Prices'
import OpenHours from './components/OpenHours'
import Footer from "./components/Footer";
const Home = () => {
  return (
    <div className={styles.parent}>
        <Header />
        <DescriptionMain />
        <Trainings />
        <Prices/>
        <OpenHours />
        <Footer />
    </div>
  );
};

export default Home;
